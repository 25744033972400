<template>
  <div class="home">
    <div class="font-weight-medium intro">
      <overline>Our Mission</overline>
      <h1>Unique High-Quality Artwork based on your preferences</h1>
    </div>

    <v-container fluid class="custom-strip">
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/rouge-blue.webp"
          contain
        ></v-img>
      </v-container>
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/abstract-1.webp"
          contain
        ></v-img>
      </v-container>
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/chaos.webp"
          contain
        ></v-img>
      </v-container>
    </v-container>

    <v-container fluid class="custom-strip">
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/Frame-Portrait-Marilyn-Monroe.webp"
          contain
        ></v-img>
      </v-container>
      <v-container fluid class="notify">
        <caption>Coming Soon</caption>
        <subtitle-1>Custom Portrait</subtitle-1>
        <overline>Unique High-Quality Portrait based on your reference photo</overline>
        <v-btn 
          outlined 
          rounded
          color="#7dc5ff"
          x-large
          max-width="20em"
          class="center"
          @click="dialog = !dialog"
        >Get Notify</v-btn>
      </v-container>
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/un-verre.webp"
          contain
        ></v-img>
      </v-container>
    </v-container>

    <v-container fluid class="custom-strip">
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/ocean.webp"
          contain
        ></v-img>
      </v-container>
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/red-hat.webp"
          contain
        ></v-img>
      </v-container>
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/blue-red-ghost.webp"
          contain
        ></v-img>
      </v-container>
    </v-container>

    <v-container fluid class="custom-strip">
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/blossom.webp"
          contain
        ></v-img>
      </v-container>
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/red-kiss.webp"
          contain
        ></v-img>
      </v-container>
      <v-container fluid class="art">
        <v-img
          src="../assets/images/Webp/Artworks/untitled-2.webp"
          contain
        ></v-img>
      </v-container>
    </v-container>




    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card>
          
      </v-card>

    </v-dialog>

    <!-- <v-carousel
    cycle
    height="900"
    hide-delimiter-background
    show-arrows-on-hover
    class="showcase-strip"
    >
    <v-carousel-item
      class="showcase-image"
      v-for="showcase in showcases"
      :key="showcase.id"
      :src="showcase.src"
    >
    </v-carousel-item>
    </v-carousel> -->
  </div>
</template>

<script>

  export default {
    name : 'App',
    data () {
      return {
        dialog: false,
        slides: [
          `https://picsum.photos/800`,
          `https://picsum.photos/400`,
          `https://picsum.photos/100`,
          "../assets/images/Webp/Artworks/Showcase/Bedroom-Design-Black-Roses.webp",
          `https://picsum.photos/100`,
        ],
        showcases: [
          {
            id: "1",
            src: '../assets/images/Webp/Artworks/Showcase/Bedroom-Design-Black-Roses.webp',
            thumbnail: "",
            description: ""
          },
          {
            id: "2",
            src: '../assets/images/Webp/Artworks/Showcase/Bedroom-Design-Sunset-Sea.webp',
            thumbnail: ""
          },
          {
            id: "3",
            src: '../assets/images/Webp/Artworks/Showcase/Frame-Portrait-Marilyn-Monroe.webp',
            thumbnail: ""
          },
          {
            id: "4",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-1819-Illustration.webp',
            thumbnail: ""
          },
          {
            id: "5",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-Blue-Lighting-Illustration.webp',
            thumbnail: ""
          },
          {
            id: "6",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-Blue-Red-Illustration.webp',
            thumbnail: ""
          },
          {
            id: "7",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-Blue-Red-lightning.webp',
            thumbnail: ""
          },
          {
            id: "8",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-Color-Face-Illustration.webp',
            thumbnail: ""
          },
          {
            id: "9",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-Portrait-Marilyn-Monroe-2.webp',
            thumbnail: ""
          },
          {
            id: "10",
            src: '../assets/images/Webp/Artworks/Showcase/Modern-Bedroom-Design-Dark-Rose.webp',
            thumbnail: ""
          },
          {
            id: "11",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-Portrait-Marilyn-Monroe.webp',
            thumbnail: ""
          },
          {
            id: "12",
            src: '../assets/images/Webp/Artworks/Showcase/Living-Room-Design-Red.webp',
            thumbnail: ""
          },
          {
            id: "13",
            src: '../assets/images/Webp/Artworks/Showcase/Sitting-Area-Design-Modern-Black-Rose.webp',
            thumbnail: ""
          },
        ],
      }
    },
      
    components: {
      
    }
  }
</script>

<style scoped>
.primary_color {
  color: #7dc5ff;
}

.secondary_color {
  color: #f1c40f;
}

.third_color {
  color: #00a652;
}

.center {
  align-self: center;
}

.bottom {
  align-self: flex-end;
}

.normal_font {
  font-size: 28px;
}

.home {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
}

.intro {
  margin-top: 10em;
  margin-bottom: 10em;
}

.home overline {
  font-size: 28px;
  margin-bottom: 5em;
}

.home h1 {
  font-size: 35px;
  margin-top: 1em;
}

.custom-strip {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0.4em;
  width: 100%;
  height: 20%;
  justify-content: center;
}

.container {
  padding: 0;
}

.art {
  max-width: 33%;
  height: 45%;
}

.notify {
  max-width: 33%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 6em;
}

.notify caption {
  font-size: 2em;
}

.notify subtitle-1 {
  font-size: 5em;
  color:#f1c40f;
}

.notify overline {
  font-size: 3.2em;
  color: black;
  margin-bottom: 1em;
}

.showcase-image {
  min-width: 200px;
  max-width: 100%;
}

@media only screen and (max-width: 1904px) {

  .notify {
    padding: 1em;
  }

}


@media only screen and (max-width: 1500px) {

  .notify caption {
    font-size: 1.2em;
  }

  .notify subtitle-1 {
  font-size: 2.3em;
  }

  .notify overline {
    font-size: 1.8em;
  }

}

/* For Mobile phones */
@media only screen and (max-width: 1100px) {

  .custom-strip {
    display: flex;
    flex-flow: column wrap;
  }

  .notify {
    max-width: 100%;
    height: 45em;
    flex-flow: column wrap;
  }

  .notify caption {
    padding-bottom: .5em;
    font-size: 2.2em;
  }

  .notify subtitle-1 {
    padding-bottom: 1em;
    font-size: 3.5em;
  }

  .notify overline {
    padding-bottom: 2em;
    font-size: 2.2em;
  }

  .art {
    max-width: 100%;
  }
}

</style>
