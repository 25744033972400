<template>
  <v-app id="app">
    <v-app-bar
      app
      flat
      color="white"
      height="100"
      class="app-bar"
    >
      <!-- Navigation bar -->
      <div id="nav" class="nav-items">
        <!-- Site Logo -->
        <div class="logo">
          <a href="/">
            <img src="./assets/logo/Logo-Color.svg" alt="mavcollection site logo"
          height="80" width="80">
          </a>
        </div>

        <!-- Navigation Links -->
        <div fluid id="nav-links" class="nav-links">
          <div class="routes"><router-link to="/">Home</router-link></div> 
          <div class="routes"><router-link to="/archive">Archive</router-link></div>  
          <div class="routes"><router-link to="/shop">Art Shop</router-link></div>  
          <div class="routes"><router-link to="/custom">Custom Order</router-link></div>
          <!-- Search Bar -->
          <div class="search"></div>
          <!-- User logins -->
          <div class="user"></div>
          <!-- Shopping Cart -->
          <div class="cart"></div>
        </div>

        <div class="list-menu">
          <v-btn
            elevation="0"
            icon
            large
            @click="overlay = !overlay"
          >
            <v-img
              contain
              max-height="50"
              max-width="50"
              src="./assets/menu/list.svg"
            ></v-img>
          </v-btn>
        </div>
        
      </div>

    </v-app-bar>

    <v-overlay
      :absolute="absolute"
      :value="overlay"
      :opacity="0.95"
      width="100%"
      height="100%"
      class="overlay-menu"
      id="nav"
      @click="overlay = false"
    >
      <v-btn
        elevation="0"
        icon
        large
        @click="overlay = false"
        class="close"
      >
        <v-img
          contain
          max-height="50"
          max-width="50"
          src="./assets/menu/x.svg"
        ></v-img>
      </v-btn>

      <!-- Navigation Links -->
      <div fluid id="mobile-links" class="mobile-links">
        <div class="routes"><router-link to="/">Home</router-link></div>
        <div class="routes"><router-link to="/artist">The Artist</router-link></div> 
        <!-- <div class="routes"><router-link to="/archive">Archive</router-link></div>   -->
        <div class="routes"><router-link to="/shop">Art Shop</router-link></div>  
        <!-- <div class="routes"><router-link to="/custom">Custom Order</router-link></div> -->
        <!-- Search Bar -->
        <div class="search"></div>
        <!-- User logins -->
        <div class="user"></div>
        <!-- Shopping Cart -->
        <div class="cart"></div>
      </div>

    </v-overlay>


    <v-main>
      <router-view/>
    </v-main>

    <v-footer class="footer" color="white">
      <!-- Site Footer -->
      <div id="footer" class="footer">
        <div id="strip" class="strip">
          <!-- About Section -->
          <div id="about" class="about">
            <p>{{ about }}</p>
          </div>
          <!-- Terms and Conditions Section -->
          <div id="terms" class="terms">
            <caption>Terms, Conditions & Policies</caption>
            <ul class="term-list">
              <!-- <li :v-for="term in terms">{{ term }}</li> -->
              <li><a href="" target="_blank" rel="noopener">Terms & Conditions</a></li>
              <li><a href="" target="_blank" rel="noopener">Privacy Policy</a></li>
              <li><a href="" target="_blank" rel="noopener">Return Policy</a></li>
            </ul>
          </div>
          <!-- Contact Section -->
          <div id="contact" class="contact">
            <caption>Contact Us</caption>
            <ul class="contact-list">
              <!-- <li :v-for="contact in contacts">{{ contact }}</li> -->
              <li><a href="" target="_blank" rel="noopener">Email Us</a></li>
              <li><a href="" target="_blank" rel="noopener">323 - 628 - 2784</a></li>
            </ul>
          </div>
          <!-- Social Section -->
          <div id="social" class="social">
            <caption>Follow Us</caption>
            <ul class="social-list">
              <!-- <li :v-for="social in socials">{{ social }}</li> -->
              <li><a href="https://www.instagram.com/mavcollections/" target="https://www.instagram.com/mavcollections/" rel="noopener"><img src="./assets/socials/instagram-logo.svg" alt="instagram logo image" width="32"></a></li>
              <li><a href="https://unsplash.com/@mavcollections" target="https://unsplash.com/@mavcollections" rel="noopener"><img src="./assets/socials/unsplash-icon.svg" alt="instagram logo image" width="32"></a></li>
            </ul>
          </div>
        </div>
        <!-- Copyright Section -->
        <div id="copyright" class="copyright">
          ©2021  mavcollections. All rights reserved.
        </div>
      </div>
    </v-footer>
  </v-app>
</template>


<script>
  export default {
    data () {
      return {
        about: "mavcollections was created with innovation in mind. We love creativity and design. We believe that art can engage, inspire, and connect people across the globe. We think a good work of art results from creative innovation and collaboration. We are a studio of several passionate artists. We offer prints of paintings, drawings, photography, and custom artwork, and our list is still growing. It is a pleasure to connect with you and begin the visual dialogue, and together create a unique piece of art that meets your vision and exceeds your expectation.",
        logo: "",
        contacts: [
          "Email Us",
          "Phone"
        ],
        terms: ["test"],
        socials: ["test"],
        overlay: false,

      }
    },
  }

</script>


<style>

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap');

#app {
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #020e1a;
  font-size: 12px;
  
}



#nav {
  margin: 2px;
  padding: 2px;
}

#nav a {
  font-weight: bold;
  color: #020c16;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #808080;
  text-decoration: none;
}

.nav-items {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
}

.logo {
  align-self: flex-start;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
}

.nav-links {
  display: flex;
  align-self: flex-end center;
  flex-flow: row wrap;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: auto;
  font-size: 22px;
}

.routes {
  margin: 30px;
}

.footer {
  background-color: white;
  height: 30%; 
  font-size: 18px;
}


.strip {
  display: flex;
  justify-content: space-around;
}

.strip ul {
  margin-top: 1.0em;
  list-style: none;
}

.strip ul li {
  text-align: right;
  margin-bottom: 0.2rem;
}

.strip a {
  text-decoration: none;
}

.strip caption {
  text-align: right;
  text-decoration-line: underline;
}

.about {
  max-width: 40%;
  text-align: left;
}

.terms {
  display: flex;
  flex-flow: column wrap;
}

.terms ul li {
  text-decoration: none;
}

.contact {
  display: flex;
  flex-flow: column wrap;
}

.social {
  display: flex;
  flex-flow: column wrap;
}

.copyright {
  text-decoration: none;
}

.close {
  width: 5em;
  size: 5em;
  margin-bottom: 8em;
}

.overlay-menu {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.mobile-links {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  font-size: 4em;
}

.list-menu {
  display: none;
}

@media only screen and (max-width: 960px) {

  .nav-links {
    display: none;
  }

  .list-menu {
    display: block;
  }

  .nav-items {
    justify-content: space-between;
  }

  .nav-items img {
    width: 60px;
    height: 60px;
  }

  .strip {
    flex-flow: column wrap;
    justify-content: center;
  }

  .strip ul li {
    text-align: center;
  }

  .strip caption {
    text-align: center;
  }

  .about {
    max-width: 100%;
  }

}

</style>


